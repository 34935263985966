import React,{useEffect,useState, useContext} from 'react'
import { LanguageContext } from '../languageContext'
import '../styles/Contacts.scss'
import { useLoadingContext } from "react-router-loading";
import { Helmet } from 'react-helmet';
import Layout from './Layout';
const Contacts = () => {
   const [name, setName] = useState('')
   const [phone, setPhone] = useState('')
   const [email, setEmail] = useState('')
   const [inquiry, setInquiry] = useState('')
   const loadingContext = useLoadingContext();
   const { lang } = useContext(LanguageContext)
   
      const handleOnChange = (event) => {
         const inputType = event.target.getAttribute('name')
         switch (inputType) {
            case "name":
               setName(event.target.value)
               break;
            case "phone":
               setPhone(event.target.value)
               break;
            case "inquiry":
               setInquiry(event.target.value)
            break;
            default:
               setEmail(event.target.value)
               break;
            }
         }

      useEffect(() => {
         fetch(fethUrl)
            .then(res => res.json())
            .then(result => setItems(result))
            .catch(error => {
               setError(error)
               console.log(error)
            })
            .finally(() => {
               setIsLoaded(true)
               loadingContext.done()
            })
       }, [lang])

      const [error, setError] = useState(null);
      const [isLoaded, setIsLoaded] = useState(false);
      const [items, setItems] = useState([]);
      const fethUrl = `https://esimus-property.com/app/api/contact?locale=${lang}&populate[contact][fields]`
      
      if (error) {
        return <div>Ошибка: {error.message}</div>;
      } else if (!isLoaded) {
        return <div className='fetch-loader'></div>;
      } else {
  return (

<>
<Layout>
<Helmet>
   <title>Contacts | Esimus Property</title>
</Helmet>
   <main>
   <section className="contacts">
      <div className="container contacts__flex-wrapper">
         <div className="contacts__left-side">
            <h3>{items.data.attributes.title}</h3>
            <ul className="contacs__list">
               {
                  items.data.attributes.contact.map(item=>(
                     item.text=='EsimusPropertyGroup' ? <li key={item.id}><a rel="nofollow" target="_blank" href={item.link}>{item.text}</a></li>
                     
                     :<li key={item.id}><a href={item.link}>{item.text}</a></li>
                  ))
               }
               
               
            </ul>
            <a href='https://www.facebook.com/Esimus' target="_blank">
               <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M50 25C50 11.1914 38.8086 0 25 0C11.1914 0 0 11.1914 0 25C0 38.8086 11.1914 50 25 50C25.1465 50 25.293 50 25.4395 49.9902V30.5371H20.0684V24.2773H25.4395V19.668C25.4395 14.3262 28.7012 11.416 33.4668 11.416C35.752 11.416 37.7148 11.582 38.2812 11.6602V17.2461H35C32.4121 17.2461 31.9043 18.4766 31.9043 20.2832V24.2676H38.1055L37.2949 30.5273H31.9043V49.0332C42.3535 46.0352 50 36.416 50 25Z" fill="#99C455"/>
               </svg>
            </a>
         </div>
         <div className="form__wrap">
            <div className="form__title">{items.data.attributes.formTitle}</div>
            <form
               className="form"
               method='POST'
               action='https://jsonb.ru/esimusPropertyFeedbackForm.php'
               // action='mailto:info.esimus@gmail.com'
               name="form">
               <input
               value={name}
                  type="text"
                  placeholder={items.data.attributes.name}
                  autoComplete="off"
                  required
                  onChange={handleOnChange}
                  name="name"
               />
               <input
               value={email}
                  type="email"
                  placeholder={items.data.attributes.email}
                  autoComplete="off"
                  required
                  onChange={handleOnChange}
                  name="email"
               />
               <input
                  value={phone}
                  type="tel"
                  placeholder={items.data.attributes.phone}
                  onChange={handleOnChange}
                  autoComplete="off"
                  required
                  name="phone"
               />
               <textarea
                  value={inquiry}
                  placeholder={items.data.attributes.inquiry}
                  autoComplete="off"
                  required
                  onChange={handleOnChange}
                  name="inquiry"
               ></textarea>
               <button type="submit" className="form__submit">{items.data.attributes.submit}</button>
            </form>
         </div>

          {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d104976.96428138374!2d32.958071445181886!3d34.691806414894344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14e73316ac02f49d%3A0xdaa97f957025b012!2sLimassol%2C%20Cyprus!5e0!3m2!1sen!2sru!4v1635153777091!5m2!1sen!2sru"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            width="100%"
            height="600px"
         ></iframe>  */}
      </div>
   </section>
</main>
</Layout>
</>

  )
}
}
export default Contacts