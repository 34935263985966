import React,{useState,useEffect, useRef, useContext} from 'react'
import { LanguageContext } from '../languageContext';
import '../styles/Header.scss';
import { Link, useLocation } from "react-router-dom";
import getFetch from './functions/fetching';

const Header = () => {

   const { lang } = useContext(LanguageContext)
   const { pathname } = useLocation();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const [isLangTabOpen, setIsLangTabOpen] = useState(false)
    const [items, setItems] = useState([]);
    const [locales, setLocales] = useState([])
    const ref = useRef(null)
    const screenRef = useRef(null)
    const localesRef = useRef(null)
    const localesMenuRef = useRef(null)
    const fethUrl = `https://esimus-property.com/app/api/header?locale=${lang}&populate[logo][populate][img][fields]=url&populate[nav][fields]`

    useEffect(() => {
      if (!isFirstLoad) {
         fetchData()
      }
    }, [lang])

    useEffect(() => {
      fetch(`https://esimus-property.com/app/api/i18n/locales`)
         .then(res => res.json())
         .then(data => setLocales(data.map(d => d.code)))
         .then(() => fetchData())
         .then(() => setIsFirstLoad(false))
         .catch(err => console.warn(err))
    }, [])

    useEffect(() => {
      if (isLoaded) {
         handleMenu(false)
      }
      
    }, [pathname])

    useEffect(() => {
      function handleClickOutside(e) {
          if (ref.current && !ref.current.contains(e.target) ) {
              handleMenu(false)
          }
      }
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
          document.removeEventListener("mousedown", handleClickOutside)
      }
      }
  , [ref])

  useEffect(() => {
   function handleClickOutsideTab(e) {
       if (localesRef.current && !localesRef.current.contains(e.target) && !localesMenuRef.current.contains(e.target) && isLangTabOpen ) {
           setIsLangTabOpen(false)
       }
   }
   document.addEventListener("mousedown", handleClickOutsideTab)
   return () => {
       document.removeEventListener("mousedown", handleClickOutsideTab)
   }
   }
, [localesRef, isLangTabOpen])

  const fetchData = () => {
   getFetch(fethUrl,error,setError,isLoaded,setIsLoaded,items,setItems)
  }

   const handleMenu = (shouldOpen) => {
      if (ref) {
         if (shouldOpen) {
            ref.current.classList.remove('header__mobileMenu_hidden')
            screenRef.current.classList.add('darkened')
            document.body.classList.add('stop-scrolling')
         } else {
            ref.current.classList.add('header__mobileMenu_hidden')
            screenRef.current.classList.remove('darkened')
            document.body.classList.remove('stop-scrolling')
         }
      }
   }

   const handleLanguagePick = (selection) => {
      localStorage.setItem('lang', selection)
      if (selection !== lang) {
         const event = new CustomEvent('LANG_CHANGED', {
            detail: {
               lang: selection
            }
         })
         document.dispatchEvent(event)
      }
      setIsLangTabOpen(false)
   }


    if (error) {
      return <div>Ошибка: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className='fetch-loader'></div>;
    } else {
  return (
   <>
      <header className="header">
         <div className="container">
            <div className="header__body">
               <div className="header__logo">
                  <Link to={items.data.attributes.logo.link}>
                     <img src={"https://esimus-property.com/app/uploads/esimus_service_logo_normal_3978751c3b.png"} alt="logo" />
                  </Link>
               </div>
               <nav className="header__menu">
                  <ul>
                     {
                        items.data.attributes.nav.map(item=>(
                           <li key={item.id} className={pathname === item.link ? 'sel' : ''}><Link to={item.link}>{item.text}</Link></li>
                        ))
                     }
                  
                  </ul>
               </nav>
               <div className="locales" onClick={() => setIsLangTabOpen(prev => !prev)} ref={localesMenuRef}>
                  <div className="locales__current-language">{lang.replace('el-CY', 'gr')}</div>
                  <div className={`locales__arrow${isLangTabOpen ? ' locales__arrow_open' : ''}`}>
                     <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 1L4 4L1 1" stroke="#99C455" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                     </svg>
                  </div>
               </div>
               <div className="header__hamburger" onClick={() => handleMenu(true)}>
                  <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path fillRule="evenodd" clipRule="evenodd" d="M0.5 1.5C0.5 0.947715 0.947715 0.5 1.5 0.5H16.5C17.0523 0.5 17.5 0.947715 17.5 1.5C17.5 2.05228 17.0523 2.5 16.5 2.5H1.5C0.947715 2.5 0.5 2.05228 0.5 1.5ZM0.5 7.5C0.5 6.94772 0.947715 6.5 1.5 6.5H16.5C17.0523 6.5 17.5 6.94772 17.5 7.5C17.5 8.05228 17.0523 8.5 16.5 8.5H1.5C0.947715 8.5 0.5 8.05228 0.5 7.5ZM1.5 12.5C0.947715 12.5 0.5 12.9477 0.5 13.5C0.5 14.0523 0.947715 14.5 1.5 14.5H16.5C17.0523 14.5 17.5 14.0523 17.5 13.5C17.5 12.9477 17.0523 12.5 16.5 12.5H1.5Z" fill="#222222"/>
                  </svg>

               </div>
            </div>
         </div>
      </header>
      <div className={`locales__select${isLangTabOpen ? ' locales__select_open' : ''}`} ref={localesRef}>
         {locales.map(l => <div className={`locales__option`} key={l} onClick={() => handleLanguagePick(l)}>{l.replace('el-CY', 'gr')}</div>)}
      </div>
      <div className="header__mobileMenu-screen" ref={screenRef}></div>
         <div ref={ref} className={`header__mobileMenu header__mobileMenu_hidden`}>
            <div className="header__mobileMenu-header">
               <div className="header__mobileMenu-menu">{items.data.attributes.menu}</div>
               <svg onClick={() => handleMenu(false)} className="header__mobile-close" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7M7 7L13 13M7 7L13 1M7 7L1 13" stroke="white" strokeWidth="2" strokeLinecap="round"/>
               </svg>
            </div>
            <ul>
               {
                  items.data.attributes.nav.map(item=>(
                     <li key={item.id}><Link to={item.link}>{item.text}</Link></li>
                  ))
               }
            
            </ul>
            <a href='https://www.facebook.com/Esimus' target="_blank">
               <svg className="header__mobileMenu-facebook" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M40 20C40 8.95312 31.0469 0 20 0C8.95312 0 0 8.95312 0 20C0 31.0469 8.95312 40 20 40C20.1172 40 20.2344 40 20.3516 39.9922V24.4297H16.0547V19.4219H20.3516V15.7344C20.3516 11.4609 22.9609 9.13281 26.7734 9.13281C28.6016 9.13281 30.1719 9.26562 30.625 9.32812V13.7969H28C25.9297 13.7969 25.5234 14.7812 25.5234 16.2266V19.4141H30.4844L29.8359 24.4219H25.5234V39.2266C33.8828 36.8281 40 29.1328 40 20Z" fill="white"/>
               </svg>
            </a>
         </div>
      
   </>
   
  )
}
}

export default Header